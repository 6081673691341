import AspectRatio from '../AspectRatio/AspectRatio';
import Caption from 'legacy-mise-ui/components/MediaEmbed/Caption';
import { MediaEmbed } from '../../utilities';

interface ZypeEmbedProps extends MediaEmbed {
  autoplay?: boolean;
  token: string;
}

const ZypeEmbed = ({
  autoplay = false,
  caption,
  source,
  token,
}: ZypeEmbedProps) => {
  return (
    <div>
      <AspectRatio>
        <iframe
          allow="allowfullscreen"
          allowFullScreen
          loading="lazy"
          title={source}
          src={`https://player.zype.com/embed/${source}.html?api_key=${token}&autoplay=${autoplay}&controls=true&da=true`}
        ></iframe>
      </AspectRatio>
      <Caption caption={caption} />
    </div>
  );
};

export default ZypeEmbed;
